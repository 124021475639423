<template>
    <div>
      <b-row class="m-0">
        <b-col
          class="content-header"
          cols="12"
        >
          <h2 class="content-header-title float-left m-0 pr-1 mr-1">
            {{ $route.meta.pageTitle }}
            <span class="text-danger" v-show="archive">&nbsp;(archive)</span>
          </h2>
          <div class="content-header-search mr-1">
            <b-form-input
              id="searchPool"
              v-model="searchPool"
              placeholder="Rechercher une tâche"
              v-on:input="onFilterTextBoxChanged()"
              class="w-full"
            />
          </div>
          <div class="content-header-actions">
            <b-button
              variant="primary"
              @click="popupCreateCommonMission('')"
              class="d-flex px-1 mr-1"
            > 
              <feather-icon icon="PlusIcon" />
              <span class="d-none d-md-block">Nouveau</span> 
            </b-button>
            <b-dropdown class="bg-outline-primary btn-group-icon" variant="outline-primary">
              <template #button-content>
                <span class="material-icons-outlined m-0 p-0">settings</span>
              </template>
              <b-dropdown-item class="w-full border-bottom-grey-light" @click="archive = !archive">{{ !archive ? "Voir les tâches archiv&eacute;s" : "Voir les tâches actifs" }}</b-dropdown-item>
              <b-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</b-dropdown-item>
              <b-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <div class="content-grid">
        <div class="text-center flex-center" v-if="isLoadingCommonMissionsList || isLoadingCommonMissionsListArchived">
          <div class="loading-bg-inner">
            <div class="loader">
              <div class="outer"></div>
              <div class="middle"></div>
              <div class="inner"></div>
            </div>
          </div>
          <div class="mt-5">
            <br /><br /><br />
            {{'Chargement en cours de la liste des tâches...'}}
          </div> 
        </div>
        <ag-grid-vue v-else style="width: 100%; height: 100%;"
            class="ag-theme-material"
            :class="archive ? 'ag-archived' : ''"
            :rowData="gridData"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            @rowClicked="onRowClicked">
        </ag-grid-vue>
      </div>
      <popup-mission ref="popupCommonMission"/>
    </div>
  </template>
  <script>
  import { AgGridVue } from "ag-grid-vue"
  import { agGridLocaleFR } from "@/data/agGridLocaleFR"
  
  import {
    BButton,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { mapGetters, mapActions } from 'vuex'
  
  
  import PopupMission from '@/components/mission/PopupMissionFromCommonMission'
  
  
  export default {
    components: {
      BButton,
      AgGridVue,
      PopupMission
    },
    directives: {
      Ripple
    },
    data () {
      return {
        filter: false,
        sidebar:false,
        activeSidebarPool:false,
        gridOptions:{
          localeText: agGridLocaleFR,
          columnDefs: null,
          defaultColDef: {
            flex: 1,
            minWidth: 100,
            editable: false,
          },
          enableRangeSelection: true,
          pagination:true,
          paginationPageSize:20,
        },
        archive: false,
        gridApi: null,
        columnApi: null,
        searchPool: ''
      }
    },
    computed: {
      ...mapGetters([
        'missionsList',
        'isLoadingCommonMissionsList',
        'isLoadingCommonMissionsListArchived',
        'isOpenSidebarMission',
      ]),
      gridData () {
        if (this.archive) {
          return this.$store.getters.commonMissionsListArchived
        } else {
          return this.$store.getters.commonMissionsList
        }
      }
    },
    beforeMount() {
      this.gridOptions.columnDefs = [
        { field: 'label', headerName: 'Nom', resizable:true,  sortable: true, minWidth: 160},
        { field: 'missionType.label', headerName: 'Type de tâche', resizable:true,  sortable: true, minWidth: 160},
        { field: 'device', headerName: 'Appareil', resizable:true,  sortable: true, minWidth: 160, cellRenderer: params => {
            return params.value == null ? "Aucun appareil" : params.value;
          }, 
        },
      ];
    },
    created(){
      this.fetchCommonMissionsList();
      this.fetchCommonMissionsListArchived();
    },
    methods: {
      ...mapActions([ 
        'fetchCommonMissionsList',
        'fetchCommonMissionsListArchived',
        'deletePool'
      ]),
  
      // Gestion d'AgGrid
      autoSizeAll(skipHeader) {
        const allColumnIds = [];
        this.gridApi.getColumnDefs().forEach((column) => {
          allColumnIds.push(column.field);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
      },
      onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setHeaderHeight(36);
        this.$nextTick(() => {
          //this.autoSizeAll(true)
        })
      },
      onRowClicked(event){
        if (this.archive) {
          this.popupCreateCommonMissionArchived(event.data.id)
        } else{
          this.popupCreateCommonMission(event.data.id)
        }
        
      },
      onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter(
          this.searchPool
        );
      },
      togglePanelColumn(){
        if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
          this.gridOptions.sideBar = {
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Colonnes',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
            ],
            defaultToolPanel: 'columns',
          }
          this.filter=true;
        }else{
          this.gridOptions.sideBar= null
          this.filter=false;
        }
      },
      exportDataXLS(){
        this.gridApi.exportDataAsExcel();
      },
      exportDataCSV(){
        this.gridApi.exportDataAsCsv();
      },
      popupCreateCommonMission(id){
        this.$bvModal.show('popup-common-mission')
        this.$refs['popupCommonMission'].popupCreateCommonMissionEvent(id)
      },
      popupCreateCommonMissionArchived(id){
        this.$bvModal.show('popup-common-mission')
        this.$refs['popupCommonMission'].popupCreateCommonMissionArchivedEvent(id)
      },
    }
  }
  </script>
  